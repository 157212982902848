.container {
  @apply tw-w-full tw-mx-auto tw-px-8;

  @media screen and (min-width: theme('screens.lg')) {
    max-width: theme('screens.lg');
  }

  @media screen and (min-width: theme('screens.xl')) {
    max-width: theme('screens.xl');
  }

  @media screen and (min-width: theme('screens.xxl')) {
    max-width: theme('screens.xxl');
  }

  @media screen and (min-width: theme('screens.xxxl')) {
    max-width: theme('screens.xxxl');
  }
}

.container-profile {
  @apply tw-w-full tw-mx-auto tw-px-8;

  @media screen and (min-width: theme('screens.lg')) {
    @apply tw-px-16;
  }

  @media screen and (min-width: theme('screens.xxl')) {
    @apply tw-px-24;
  }

  @media screen and (min-width: theme('screens.max')) {
    @apply tw-px-32;
  }
}

.AB-Item {
  &.has-inset-bg {
    & + & {
      margin-top: -1.25rem;
    }
  }
}
