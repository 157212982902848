.AB-Timeline {
    .scroller-list,
    .media-item-headline {
        @media screen and (min-width: theme("screens.lg")) {
            top: theme("spacing.24");
        }
    }

    .scroller-item {
        &::after {
            @apply tw-block tw-absolute tw-right-0 tw-bg-white;

            content: "";
            top: 50%;
            transform: translate(50%, -50%);
            width: 19px;
            height: 19px;
            border: 2px solid theme("colors.gray.300");
            border-radius: 19px;
        }

        &.is-active {
            .scroller-item-link {
                @apply tw-text-gray-800;
            }

            &::after {
                @apply tw-bg-gray-800 tw-border-gray-800;
            }
        }
    }
}
