.Footer {
    .links-container .pimcore_tag_link_text {
        @extend .Link, .is-anchor;
    }

    .links-big .pimcore_tag_link_text {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 8rem;
        color: theme('colors.black');
        width: 300px;
        border-bottom: 1px solid rgb(204, 204, 204, 30%);
        margin: 0 1.25rem;
        background-image: none;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            height: 6px;
            width: 6px;
            border-top: 2px solid theme('colors.brand');
            border-right: 2px solid theme('colors.brand'); 
        } 
    }
}