.Input {
  @apply tw-text-black tw-py-4 tw-px-8 tw-w-full tw-rounded-2 tw-border tw-border-gray-300;

  &::placeholder {
    color: theme('colors.gray.300');
  }

  // white background, light-gray input
  &.is-default {
    @apply tw-bg-gray-100;

    &:hover {
      @apply tw-shadow-md;
    }

    &:active,
    &:focus {
      @apply tw-border-gray-500;
    }

    &[disabled],
    &.is-disabled {
      @apply tw-bg-white tw-text-gray-300 tw-border-gray-300 tw-pointer-events-none;
    }
  }

  // light-gray background, white input
  &.is-light {
    @apply tw-bg-white;

    &:hover {
      @apply tw-shadow-md;
    }

    &[disabled],
    &.is-disabled {
      @apply tw-pointer-events-none tw-text-gray-400;
    }
  }

  // dark-gray background, black input
  &.is-dark {
    @apply tw-text-white tw-bg-gray-850 tw-border-black;

    &:hover {
      @apply tw-shadow-md;
    }

    &[disabled],
    &.is-disabled {
      @apply tw-bg-gray-800 tw-border-gray-850 tw-pointer-events-none;
    }
  }

  &.is-axis-input {
    @apply tw-text-gray-500 tw-py-3 tw-px-5 tw-text-right;

    @media screen and (min-width: theme('screens.xxl')) {
      @apply tw-text-lg;
    }
  }

  &.has-icon-left {
    @apply tw-pl-12;
  }

  &.has-icon-right {
    @apply tw-pr-12;
  }

  &.has-vbla {
    @apply tw-pl-40;
  }

  &.is-pagination {
    @apply tw-py-3 tw-px-5;

    -webkit-appearance: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.is-invalid {
    @apply tw-border-brand;
  }
}

.CheckboxInput {
  .input-wrap {
    @apply tw-pl-8;

    .Checkbox {
      line-height: 1.2;

      &::before {
        @apply tw-absolute tw-top-0 tw-left-0 tw-block tw-rounded tw-w-5 tw-h-5;

        content: '';
        border: solid 2px theme('colors.gray.500');
        pointer-events: none;
      }

      &::after {
        visibility: hidden;
      }
    }

    &.is-red {
      @apply tw-pl-6;

      .Checkbox::before {
        top: 0.25em;
        height: 1em;
        width: 1em;
        background-color: white;
        border: solid 2px theme('colors.brand');
      }

      input:checked ~ .Checkbox::after {
        left: 8px;
        top: 13px;
        width: 5px;
        height: 9px;
      }

      input:checked ~ .Checkbox::before {
        border-color: theme('colors.brand') !important;
        background-color: theme('colors.brand');
      }

      input:checked ~ .Checkbox::after {
        border-color: theme('colors.white') !important;
      }
    }

    &.is-light .Checkbox::before {
      @apply tw-bg-white;
    }

    .input:checked ~ .Checkbox.is-default {
      color: theme('colors.gray.800');
    }

    .input:checked ~ .Checkbox.is-dark {
      &::before {
          border-color: theme('colors.brand') !important;
      }

      &::after {
          border-color: theme('colors.brand') !important;
      }
    }

    .Checkbox.is-dark {
      color: theme('colors.white');

      &::after {
        color: theme('colors.brand');
      }
    }
  }

  .input {
    @apply tw-absolute tw-left-0 tw-w-4 tw-h-4;

    z-index: -1;
    opacity: 0;

    &:checked {
      ~ .Checkbox {
        &::before {
          border-color: theme('colors.black');
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          left: 10px;
          top: 10px;
          width: 5px;
          height: 10px;
          border: solid theme('colors.black');
          border-width: 0 2px 2px 0;
          transform: translate(-50%,-50%) translateY(-2px) rotate(45deg);
          visibility: visible;
        }
      }
    }
  }
}

.RadioInput {
  &,
  label {
    @apply tw-cursor-pointer tw-select-none;
  }

  &.is-disabled {
    .Radio .label-text,
    .Radio::before {
      @apply tw-opacity-50;
    }

    .input-wrap,
    .label-text {
      @apply tw-cursor-not-allowed;
    }
  }

  .input-wrap {
    @apply tw-pl-8;
  }

  .input {
    @apply tw-absolute;

    left: 0;
    width: 1rem;
    height: 1rem;
    opacity: 0;
    z-index: -1;

    &:checked {
      ~ .Radio {
        &::before {
          border-width: 2px;
        }

        &::after {
          visibility: visible;
        }

        &.is-default,
        &.is-light {
          &::before {
            @apply tw-border-black;
          }

          &::after {
            @apply tw-bg-black;
          }
        }

        &.is-dark {
          &::before {
            @apply tw-border-brand;
          }

          &::after {
            @apply tw-bg-brand;
          }
        }
      }
    }
  }

  .Radio {
    display: flex;
    align-items: center;

    &.is-default,
    &.is-light,
    &.is-dark {
      &::before {
        @apply tw-absolute tw-top-0 tw-left-0 tw-block tw-rounded-full tw-w-5 tw-h-5;

        content: '';
        border: 1px solid theme('colors.gray.400');
        pointer-events: none;
      }

      &::after {
        @apply tw-absolute tw-block tw-rounded-full tw-w-3 tw-h-3;

        content: '';
        top: 4px;
        left: 4px;
        visibility: hidden;
      }
    }

    &.is-dark {
      &::before {
        border-color: theme('colors.gray.300');
      }

      &::after {
        background-color: theme('colors.white');
      }

      .label-text {
        @apply tw-text-white;
      }
    }

    &.is-invalid {
      &::before {
        @apply tw-border-brand;
      }
    }
  }
}

.InputError {
  padding: 3px 7px;
  width: max-content;
  min-width: 110px;
  max-width: calc(100% - 2rem - 2rem);
  transform: translate(2rem, -0.75rem);
  border-radius: theme('borderRadius.2');

  img {
    margin-right: 7px;
  }

  &::before {
    @apply tw-absolute;

    content: '';
    bottom: calc(100% - 4px);
    left: 10px;
    border-top: 8px solid theme('colors.brand');
    border-right: 8px solid theme('colors.brand');
    transform: rotate(45deg);
    z-index: -1;
  }
}
