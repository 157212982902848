.AB-Offer-Teaser {
    .list-dashed {
        li {
            @apply tw-relative tw-flex tw-justify-between tw-my-2;

            &::before {
                content: "";
                position: absolute;
                top: 1em;
                height: 1px;
                left: 0;
                right: 0;
                border-bottom: 1px dashed theme("colors.gray.300");
            }

            span {
                @apply tw-relative;
            }
        }
    }
}
