.AB-Header-Attachments {
    background-image: url("/bundles/lindner/images/bg-header-attachments.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: theme("screens.md")) {
        background-size: 100% auto;
        background-position: 0 0;
    }

    .anchor-list {
        &:hover .anchor-item {
            opacity: 0.5;
        }
    }

    .anchor-item {
        transition: opacity 0.3s theme("transitionTimingFunction.deceleration");

        &:hover {
            opacity: 1 !important;
        }
    }

    .anchor-link {
        position: relative;
        display: block;
        width: 100%;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 0.5em;
            height: 0.5em;
            border-right: 1px solid theme("colors.brand");
            border-bottom: 1px solid theme("colors.brand");
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
