.AB-Logo-Header {
    @apply tw-bg-gray-800;

    ol,
    ul {
        @apply tw-list-inside tw-font-bold;
    }

    ol {
        @apply tw-list-decimal;
    }

    ul {
        @apply tw-list-disc;
    }
}
