.AB-Simple-Headline {
    @apply tw-bg-gray-100;

    &.is-white {
        @apply tw-bg-white;
    }

    &.is-dark {
        @apply tw-bg-gray-800 tw-text-white;
    }
}
