.TractorLdrive {
    .intro-container {
        @media screen and (min-width: theme("screens.lg")) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .intro-left strong,
    .intro-right strong,
    .description strong {
        @apply tw-text-white;
    }

    .ldrive-device {
        &::after {
            content: "";
            display: block;
            position: absolute;
            z-index: 10;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/bundles/lindner/images/ldrive-arrow-twist.png");
            height: 6rem;
            width: 4rem;
            top: 12%;
            left: 10%;
        }
    }

    .ldrive-hand {
        left: -4%;
        transform: scale(1.18);
        transform-origin: bottom center;
    }
}
