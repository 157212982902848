.Headline {
  &.is-size-1 { // 36 - 80px
    @apply tw-font-poppins tw-font-black tw-italic tw-uppercase tw-leading-h1;

    font-size: 2.25rem;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 3.75rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 5rem;
    }
  }

  &.is-size-2 { // 28 - 36px
    @apply tw-font-black tw-leading-h2;

    font-size: 1.75rem;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 2rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 2.25rem;
    }
  }

  &.is-size-3 { // 24 - 36
    @apply tw-font-light tw-leading-h3 tw-text-2xl;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 2rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 2.25rem;
    }
  }

  &.is-size-4 { // 20 - 28px
    @apply tw-font-bold tw-text-xl tw-leading-h4;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 1.75rem;
    }
  }

  &.is-size-5 {
    @apply tw-font-bold tw-leading-relaxed tw-text-2xl;
  }

  &.is-weight-black {
    @apply tw-font-black;
  }

  &.is-model-name {
    @apply tw-font-poppins tw-font-black tw-italic tw-uppercase tw-leading-h1;

    font-size: 6.25rem;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 10rem;
      margin-left: -0.06em;
    }

    @media screen and (min-width: theme('screens.lg')) {
      font-size: 16rem;
      margin-left: -0.06em;
    }

    @media screen and (min-width: theme('screens.max')) {
      font-size: 22.5rem;
    }
  }
}

.hyphenate {
  hyphens: auto;
}
