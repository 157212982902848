.table {
  @apply tw-max-w-full lg:tw-w-full;

  &-traclink {
    thead, tbody {
      th, td {
        @apply tw-py-5 tw-px-3 tw-text-center;
        vertical-align: top;
        border-bottom: 1px solid theme('colors.gray.300');

        &:first-child {
          @apply tw-pl-0 tw-pr-5 tw-text-left;
          min-width: 200px;
        }

        .checkmark {
          @apply tw-relative tw-w-full tw-h-full;
          text-indent: -9999px;

          &::before {
            @apply tw-absolute tw-h-5 tw-w-5;
            content: '';
            background-image: url('/bundles/lindner/icons/checkmark.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-indent: initial;
          }
        }

        &.featured {
          @apply tw-bg-gray-500;
        }
      }
    }

    thead {
      th {
        @apply tw-text-brand tw-font-bold tw-leading-6 tw-text-lg lg:tw-text-2xl;

        &::before {
          @apply tw-text-xs tw-block tw-font-light tw-text-black;
          content: 'TracLink';
        }

        &:first-child {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}
