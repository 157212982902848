.TractorHeader {
    .Headline {
        .name-suffix {
            font-size: 0.27em;
            line-height: 0.9;
            padding-top: 0.1rem;

            @media screen and (min-width: theme("screens.md")) {
                padding-top: 0rem;
                font-size: 0.27em;
            }

            @media screen and (min-width: theme("screens.lg")) {
                padding-top: 0.3rem;
                font-size: 0.28em;
            }

            @media screen and (min-width: theme("screens.xl")) {
                padding-top: 0.3rem;
            }
        }
    }

    .name-info {
        @media screen and (min-width: theme("screens.lg")) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .model-info {
        @media screen and (min-width: theme("screens.lg")) {
            top: 50%;
            transform: translate(0, -50%) translateY(40px);
        }
    }

    .model-price {
        margin-top: -0.3em;

        @media screen and (min-width: theme("screens.lg")) {
            margin-top: 0;
        }
    }

    .header-image {
        width: calc(100% + 2rem);
        max-width: none;
        z-index: 3;

        @media screen and (min-width: theme("screens.lg")) {
            width: 72vw;
        }
    }

    .header-bg {
        z-index: 1;
        will-change: transform;

        &-ratio {
            @media screen and (min-width: theme("screens.lg")) {
                height: 52.5vw;
            }
        }
    }

    .text-shadow {
        text-shadow: 4px 8px 8px rgba(0, 0, 0, 0.05);
    }

    .swiper-pagination-bullet {
        background: theme("colors.gray.300");
        width: 10px;
        height: 10px;
        opacity: 1;

        &-active {
            background: theme("colors.brand");
        }
    }

    .header-bg-ratio {
        .plyr {
            @apply tw-w-full tw-h-full;

            &__poster {
                background-size: cover;
            }

            &__video-embed {
                aspect-ratio: auto !important;
            }
        }

        .aspect-video {
            @apply tw-w-full tw-h-full;
            aspect-ratio: auto !important;
        }
    }
}
