html {
    font-size: 16px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    scroll-behavior: smooth;
  
    --plyr-color-main: theme('colors.brand');
  
    @media (prefers-reduced-motion: reduce) {
      scroll-behavior: initial;
    }
}

body {
    position: relative;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5em;
}

[x-cloak] {
    @apply tw-hidden;
}

.read-only {
    @apply tw-border-2 tw-border-brand tw-p-16 tw-relative;

    &::before {
        @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-10 tw-bg-brand;
        content: '';
        opacity: 0.30;
        pointer-events: none;
    }
}