.AB-Fullmedia-Header-01 {
    &.has-text::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
        );
        pointer-events: none;
        z-index: 10;
    }

    .VideoAsset {
        @apply tw-h-full;

        .plyr {
            @apply tw-h-full;

            video {
                @apply tw-object-cover;
            }
        }
    }
}
