.SocialWallList {
    $gap: 20px;

    column-count: 1;
    margin-bottom: $gap;

    @media screen and (min-width: theme("screens.md")) {
        column-count: 2;
        column-gap: $gap;
    }

    @media screen and (min-width: theme("screens.lg")) {
        column-count: 3;
    }

    .SocialWallListItem {
        margin-bottom: $gap;
        break-inside: avoid;

        --plyr-control-icon-size: 18px;
        --plyr-control-spacing: 15px;
    }
}
