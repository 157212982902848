.ClassifiedAds {
    .header-image {
        opacity: 0.1;

        @media screen and (min-width: theme("screens.lg")) {
            opacity: 1;
        }
    }

    .mobile-toggle {
        &::after {
            @apply tw-absolute tw-w-3 tw-h-3 tw-border-b-2 tw-border-r-2 tw-border-brand;

            content: "";
            transform: rotate(45deg) translate(0, -50%);
            transform-origin: 100% 50%;
            transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
            top: 50%;
            right: 2.5rem;

            @media screen and (min-width: theme("screens.lg")) {
                right: 1.5rem;
            }
        }

        &.is-open {
            &::after {
                transform: rotate(225deg) translate(50%, 0%);
            }
        }
    }

    .ClassifiedAdListItemOffer {
        article {
            min-height: 16rem;
        }

        .image {
            max-height: 16rem;

            @media screen and (min-width: theme("screens.md")) {
                max-height: none;
            }
        }
    }

    .ClassifiedAdListItemPurchase{
        .user-info {
            @media screen and (min-width: theme('screens.md')) {
              &::before,
              &::after {
                content: '';
                position: absolute;
                right: -1px;
                top: 2.5rem;
                width: 1.5rem;
              }
          
              &::before {
                right: -1px;
                height: 23px;
                background: theme('colors.white');
                border-top: 1px solid theme('colors.gray.300');
              }
          
              &::after {
                right: 0;
                border-left: 1px solid theme('colors.gray.300');
                transform: rotate(-45deg) translate(0, 1px);
                transform-origin: 0 0;
                height: 33px;
              }
            }
          }
    }
}
