.LightboxThreeImages {
    .thumbnail {
        @apply tw-relative tw-overflow-hidden;

        &:after {
            content: "";
            display: block;
            padding-bottom: 65%;
        }
    }
}
