.AB-Text-Scroll-Media-Module {
    a {
        color: theme("colors.brand");

        &:hover {
            text-decoration: underline;
        }
    }

    .aligned-image {
        transform: translateX(calc(25vw + theme("spacing.3")));

        @media screen and (min-width: theme("screens.md")) {
            transform: translateX(theme("spacing.3"));
        }

        @media screen and (min-width: theme("screens.lg")) {
            transform: translateX(
                calc(
                    (
                            (100vw - theme("screens.lg")) +
                                (theme("screens.lg") * 0.16666667)
                        ) / 2
                )
            );
        }

        @media screen and (min-width: theme("screens.xl")) {
            transform: translateX(
                calc(
                    (
                            (100vw - theme("screens.xl")) +
                                (theme("screens.xl") * 0.16666667)
                        ) / 2
                )
            );
        }

        @media screen and (min-width: theme("screens.xxl")) {
            transform: translateX(
                calc(
                    (
                            (100vw - theme("screens.xxl")) +
                                (theme("screens.xxl") * 0.16666667)
                        ) / 2
                )
            );
        }

        @media screen and (min-width: theme("screens.xxxl")) {
            transform: translateX(
                calc(
                    (
                            (100vw - theme("screens.xxxl")) +
                                (theme("screens.xxxl") * 0.16666667)
                        ) / 2
                )
            );
        }
    }

    .gdpr-text-container {
        background-color: theme("colors.gray.200") !important;
    }
}
