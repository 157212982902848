.AB-Simple-Video {
    @apply tw-bg-gray-100;

    &.is-white {
        @apply tw-bg-white;
    }

    &.is-dark {
        @apply tw-bg-gray-800;
    }

    &.max-height {
        .plyr {
            max-height: calc(100vh - theme("height.20"));
        }
    }
}
