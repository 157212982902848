.EventTeaserItem {
    &::after {
        @apply tw-absolute tw-left-0 tw-right-0 tw-bottom-0 tw-border-b-2;
        content: "";
        transform: translateY(2px);
    }

    &.tw-border-gray-200 {
        &::after {
            border-color: theme("colors.gray.200");
        }
    }

    &.tw-border-gray-500 {
        &::after {
            border-color: theme("colors.gray.500");
        }
    }
}
