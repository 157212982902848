.TractorTyreSet {
    .Table {
        th {
            @apply tw-py-3 tw-px-2 tw-align-baseline;
            min-width: 9rem;

            small {
                @apply tw-block tw-text-xs tw-font-light;
            }
        }

        td {
            @apply tw-py-3 tw-text-gray-500 tw-text-center;
        }
    }
}
