.AreaBase {   
    padding-top: 20px; 
    
    .h1 {
        @extend .Headline, .is-size-1;
        margin: 1rem 0 2rem;
    }

    .h2 {
        @extend .Headline, .is-size-2;
        margin: 1rem 0 2rem;
    }

    .h3 {
        @extend .Headline, .is-size-3;
        margin: 1rem 0 2rem;
    }

    .h4 {
        @extend .Headline, .is-size-4;
        margin: 1rem 0 2rem;
    }

    .PimcoreButton .pimcore_tag_link_text {
        @extend .Button, .is-primary;
    }

    .PimcoreButtonSecondary .pimcore_tag_link_text{
        @extend .Button, .is-secondary;
    }

    .PimcoreAnchorLink .pimcore_tag_link_text {
        @extend .Link, .is-anchor;
    }

    .pimcore_wysiwyg a {
        @extend .Link, .is-red;
    }

    .PimcoreLinkRed .pimcore_tag_link_text{
        @extend .Link, .is-red;
    }

    /* Grid */
    
    .GridFive .pimcore_block_entry {
        width: 20%;
    }

    .GridFour .pimcore_block_entry {
        width: 25%;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .GridTwo .pimcore_block_entry {
        width: 50%;
    }
}