.engine-features {
    ul {
        @apply tw-list-none tw-text-gray-500 tw-mt-3;

        li {
            @apply tw-relative;

            padding-left: 1.5rem;

            &::before {
                @apply tw-absolute;

                content: "";
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                width: 5px;
                height: 5px;
                border-radius: 1rem;
                background-color: currentColor;
            }
        }
    }
}
