@import '@modules/litepicker/dist/css/litepicker.css';

.litepicker{
    --litepicker-button-prev-month-color-hover: theme('colors.brand');
    --litepicker-button-next-month-color-hover: theme('colors.brand'); 
    --litepicker-month-width: calc(var(--litepicker-day-width) * 7); // 7 days
    --litepicker-month-weekday-color: #9e9e9e;
    --litepicker-month-week-number-color: #9e9e9e;
    --litepicker-day-width: 38px;
    --litepicker-day-color: #333;
    --litepicker-day-color-hover: theme('colors.brand');
    --litepicker-is-today-color: theme('colors.brand');
    --litepicker-is-in-range-color: #bbdefb;
    --litepicker-is-locked-color: #9e9e9e;
    --litepicker-is-start-color: #fff;
    --litepicker-is-start-color-bg: theme('colors.brand');
    --litepicker-is-end-color: #fff;
    --litepicker-is-end-color-bg: theme('colors.brand');
    --litepicker-button-cancel-color: #fff;
    --litepicker-button-cancel-color-bg: #9e9e9e;
    --litepicker-button-apply-color: #fff;
    --litepicker-button-apply-color-bg: theme('colors.brand');
    --litepicker-button-reset-color: #909090;
    --litepicker-button-reset-color-hover: theme('colors.brand');
    --litepicker-highlighted-day-color: #333;
    --litepicker-highlighted-day-color-bg: theme('colors.brand');
}