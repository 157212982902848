.UsedMachinery {
    .UsedMachineryHeader {
        .header-image {
            opacity: 0.05;

            @media screen and (min-width: theme("screens.lg")) {
                opacity: 1;
            }
        }
    }

    .mobile-toggle {
        &::after {
            @apply tw-absolute tw-w-3 tw-h-3 tw-border-b-2 tw-border-r-2 tw-border-brand;

            content: "";
            transform: rotate(45deg) translate(0, -50%);
            transform-origin: 100% 50%;
            transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
            top: 50%;
            right: 2.5rem;

            @media screen and (min-width: theme("screens.lg")) {
                right: 1.5rem;
            }
        }

        &.is-open {
            &::after {
                transform: rotate(225deg) translate(50%, 0%);
            }
        }
    }

    .FilterItemAbstract {
        flex-grow: 1;

        &.is-active {
            .filter-item {
                transition-delay: 0s;

                @media screen and (min-width: theme("screens.lg")) {
                    @apply tw-bg-white tw-text-gray-800;
                }

                &::after {
                    transform: rotate(225deg) translate(50%, 0%);
                }
            }
        }

        &.is-right .filter-values {
            left: unset;
            right: 0;
            transform-origin: 100% 0;
        }

        &.is-big .filter-values {
            min-width: 100%;

            @media screen and (min-width: theme("screens.lg")) {
                min-width: 820px;
            }
        }

        &.is-big.is-centered .filter-values {
            @media screen and (min-width: theme("screens.lg")) {
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        &.is-big.is-list .filter-values {
            .CheckboxInput {
                width: 33.33333%;
            }
        }

        &.no-border-r {
            border-right: 0 !important;
        }

        &.is-list {
            .CheckboxInput {
                width: 50%;
            }
        }
    }

    .filter-item {
        @apply tw-w-full tw-text-gray-500; // tw-bg-white

        transition: background-color 0.25s cubic-bezier(0, 0, 0.2, 1) 0.1s;
        will-change: background-color;

        @media screen and (min-width: theme("screens.lg")) {
            @apply tw-w-auto tw-text-white; // tw-bg-gray-800

            &:hover,
            &:focus {
                @apply tw-bg-gray-850;
            }
        }

        &::after {
            @apply tw-absolute tw-w-3 tw-h-3 tw-border-b-2 tw-border-r-2 tw-border-brand;

            content: "";
            transform: rotate(45deg) translate(0, -50%);
            transform-origin: 100% 50%;
            transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
            top: 50%;
            right: 2.5rem;

            @media screen and (min-width: theme("screens.lg")) {
                right: 1.5rem;
            }
        }
    }

    .filter-values {
        top: 100%;
        left: 0;
        transform-origin: 0 0;
        min-width: 100%;

        @media screen and (min-width: theme("screens.lg")) {
            min-width: 430px;
        }
    }

    .max-h-8 {
        max-height: 2rem;
    }
    .UsedMachineryListItem {
        .lazy-image-container {
            max-height: 16rem;

            @media screen and (min-width: theme("screens.md")) {
                max-height: none;
            }
        }
    }

    .list-dashed {
        &-item {
            @apply tw-relative;

            &::before {
                content: "";
                position: absolute;
                top: 1em;
                height: 1px;
                left: 0;
                right: 0;
                border-bottom: 1px dashed theme("colors.gray.300");
            }
        }
    }

    .UsedMachineryTeaser {
        .text-overlay {
            display: none;
        }

        .teaser-image[data-loaded="true"] + .text-overlay {
            display: block;
        }
    }
}

.UsedMachineryDetail {
    .UsedMachineryDetailMain {
        min-height: 48vw;
    }

    .back-arrow {
        top: 50%;
        left: -1rem;
        transform: translate(0, -50%);
    }

    .offer-type-flag {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            border-left: 15px solid theme("colors.yellow.500");
            border-bottom: 15px solid transparent;
        }
    }

    .list-dashed {
        &-item {
            @apply tw-relative;

            &::before {
                content: "";
                position: absolute;
                top: 1em;
                height: 1px;
                left: 0;
                right: 0;
                border-bottom: 1px dashed theme("colors.gray.300");
            }
        }

        dd,
        dt {
            div {
                @apply tw-inline-block tw-relative;
            }
        }
    }
}
