.AB-Fullmedia-Module {
    --plyr-control-spacing: 20px;
    --plyr-control-icon-size: 30px;

    border: 1.25rem solid theme("colors.white");

    &.has-text::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0;
        pointer-events: none;
        z-index: 5;
        transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    @media screen and (min-width: theme("screens.md")) {
        &.has-text::before {
            opacity: 0.5;
        }
    }

    .container {
        left: -1.25rem;
        right: -1.25rem;
        width: unset;
    }

    & + & {
        margin-top: -1.25rem;
    }

    &.is-video {
        @apply tw-aspect-video;
        
        &.is-playing {
            &::before {
                opacity: 0;
            }
        }
    }

    .plyr.plyr--video {
        &.plyr--playing .plyr__controls {
            opacity: 1;
            pointer-events: all;
        }

        .plyr__controls {
            opacity: 0;
            pointer-events: none;
        }
    }
}
