.TractorCabin {
    .intro-headline {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .intro-image {
        position: relative;
        z-index: 5;
    }

    .info-headline {
        @media screen and (min-width: theme("screens.md")) {
            margin-right: 25%;
        }
    }
}
