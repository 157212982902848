.TractorLight {
    .intro-headline {
        @apply tw-absolute tw-text-center;

        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 66vw;

        @media screen and (min-width: theme("screens.lg")) {
            top: 30%;
        }
    }

    .intro-container {
        @media screen and (min-width: theme("screens.lg")) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .info-headline {
        @media screen and (min-width: theme("screens.md")) {
            margin-right: 25%;
        }
    }

    .intro-left strong,
    .intro-right strong,
    .description strong {
        @apply tw-text-white;
    }
}
