.NewsAnnouncementDetail {
    min-height: 48vw;

    .news-text {
        strong {
            @apply tw-text-gray-800;
        }
    }
}

.NewsPagination {
    @apply tw-relative;

    &::before {
        @apply tw-absolute tw-block tw-left-1/2 tw-top-0 tw-bottom-0 tw--translate-x-1/2 tw-border-r tw-border-gray-300;
        content: "";
    }
}
