body {
  --plyr-control-spacing: 20px;
  --plyr-control-icon-size: 30px;
}

.plyr.plyr--video {
  &.plyr--playing .plyr__controls {
    opacity: 1;
    pointer-events: all;
  }

  .plyr__controls {
    opacity: 0;
    pointer-events: none;
  }
}
