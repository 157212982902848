.BaseGridTextImage {
    max-width: 100%;

    .BaseImage {
        @apply tw-relative;

        @media screen and (min-width: theme("screens.lg")) {
            max-width: 646px;
        }

        &:after {
            @apply tw-block;
            content: "";
            padding-bottom: 65%;
        }
    }

    &.is-second {
        margin-top: -1.25rem;

        @media screen and (min-width: theme("screens.md")) {
            margin-top: 0rem;
        }
    }

    &.is-multiline-item {
        margin-top: -1.25rem;
    }

    @media screen and (min-width: theme("screens.md")) {
        max-width: calc(50% + 0.625rem);
    }
}
