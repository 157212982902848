.StoreLocator {
    @media screen and (min-width: theme("screens.lg")) {
        .overlay-width {
            width: 500px;
        }
        .list-container-height {
            max-height: 50vh;
        }

        .details-container-height {
            max-height: 80vh;
        }
    }

    .search-headline {
        font-size: 2.25rem;
    }

    .remove-padding {
        padding-left: 0;
        padding-right: 0;
    }

    .multiselect {
        @apply tw-text-sm;

        &__content {
            @apply tw-w-full;
        }

        &__element span,
        &__single {
            @apply tw-w-full;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__content-wrapper {
            @apply tw-p-0;
        }

        &__tags {
            @apply tw-px-3;
        }
    }

    .Input {
        @apply tw-px-3;
    }

    .StoreListItem {
        @media screen and (min-width: theme("screens.lg")) {
            &:last-child {
                border: none;
            }
        }
    }

    .result-index {
        top: 0.75rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .result-index-big {
        top: 1rem;
        left: 30%;
        transform: translate(-50%, -50%);
    }

    .pin-icon {
        min-width: 4.375rem;
    }

    .has-red-border {
        stroke: theme("colors.brand");
        stroke-width: 3;
    }
}
