.AB-Product-Teaser {
    .product-description-bar {
        background: rgba(255, 255, 255, 0.75);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .swiper-nav {
        @media screen and (min-width: theme("screens.lg")) {
            position: absolute;
            z-index: 2;
            right: -61px;
            bottom: 30%;
        }

        // TODO: Check how the buttons should behave on desktop
        .swiper-nav-button {
            @apply tw-flex tw-absolute tw-z-2 tw-bottom-1/2 tw-top-auto tw-m-0 lg:tw-relative lg:tw-bottom-0 tw-items-center tw-justify-center tw-bg-brand hover:tw-bg-red-600;
        }

        .swiper-button-prev {
            @apply tw-left-0 tw-right-auto;
        }

        .swiper-button-next {
            @apply tw-right-0 tw-left-auto;
        }

        .swiper-nav-button {
            width: 30px;
            height: 50px;
            margin-left: 1px;
            transform: translate(0, 50%);
            transition: background-color 170ms ease;

            @media screen and (min-width: theme("screens.md")) {
                width: 60px;
                height: 100px;
            }

            @media screen and (min-width: theme("screens.lg")) {
                transform: none;
            }

            &::before {
                @apply tw-block tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-gray-800 tw-opacity-0;
                content: " ";
                transition: opacity 170ms ease;
            }

            &.swiper-button-disabled::before {
                opacity: 0.5;
            }

            &::after {
                font-size: 24px;
                @apply tw-text-white;
            }
        }
    }

    .swiper-slide {
        padding-bottom: 87%;
    }
}
