.Userreports {
    background-image: url("/bundles/lindner/images/bg-guestbook.png");
    background-size: auto 600px;
    background-position: 50% -100px;
    background-repeat: no-repeat;

    @media screen and (min-width: theme("screens.xxl")) {
        background-size: 88vw;
    }

    .list {
        column-count: 1;

        @media screen and (min-width: theme("screens.md")) {
            column-count: 2;
            column-gap: theme("spacing.5");
        }
    }

    .UserreportItem {
        break-inside: avoid;
    }
}
