.CommonSimpleDownload {
    transition: all 0.3s theme("transitionTimingFunction.deceleration");

    &:hover {
        @apply tw-bg-brand tw-text-white;

        .BaseIcon,
        svg {
            @apply tw-text-white;
        }
    }
}
