.AB-Simple-Header {
    @apply tw-bg-gray-100;

    &.is-dark {
        @apply tw-bg-gray-800;
    }

    .header-image {
        opacity: 0.05;

        @media screen and (min-width: theme("screens.lg")) {
            opacity: 1;
        }
    }

    ol,
    ul {
        @apply tw-list-inside tw-font-bold;
    }

    ol {
        @apply tw-list-decimal;
    }

    ul {
        @apply tw-list-disc;
    }
}
