.SocialMediaLinks {
    .links {
        li + li {
            @apply tw-ml-4;
        }
    }

    &.is-in-menu {
        .links {
            li + li {
                @apply tw-ml-5;

                @media screen and (min-width: theme("screens.lg")) {
                    @apply tw-ml-8;
                }
            }
        }
    }
}
