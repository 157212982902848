.SliderParallaxSpecial {
    position: relative;
    height: 3000px;
    width: 100%;

    .slider-container {
        position: sticky;
        top: calc(var(--vh, 1vh) * 20);
        width: 100%;
        max-height: calc(var(--vh, 1vh) * 70);
        overflow: hidden;
    }

    .slider-inner-track {
        position: relative;
        display: flex;
        // width: 100%;
        height: 100%;
        will-change: transform;
    }

    .SP-Detail-Item {
        display: flex;
        max-height: calc(var(--vh, 1vh) * 70);

        .slide-inner {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    #000000 100%
                );
                z-index: 1;

                @media screen and (min-width: theme("screens.md")) {
                    top: 70%;
                }
            }

            &::after {
                content: "";
                display: block;
                position: relative;
                background: theme("colors.black");
                opacity: 0;
                transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
                padding-bottom: 130%;
                pointer-events: none;
                z-index: 2;
            }

            &-active {
                &::after {
                    opacity: 0.75;
                }

                .slide-description {
                    max-height: 300px !important;
                    opacity: 1 !important;
                    transition: max-height 2s cubic-bezier(0, 0, 0.2, 1),
                        opacity 0.3s cubic-bezier(0, 0, 0.2, 1) !important;
                }
            }

            @media screen and (min-width: theme("screens.md")) {
                &:hover {
                    &::after {
                        opacity: 0.75;
                    }

                    .slide-description {
                        max-height: 300px !important;
                        opacity: 1 !important;
                        transition: max-height 2s cubic-bezier(0, 0, 0.2, 1),
                            opacity 0.3s cubic-bezier(0, 0, 0.2, 1) !important;
                    }
                }
            }
        }

        .slide-image {
            pointer-events: none;
            user-select: none;
            transform-origin: left center;
            transform: scale(1.5);
            will-change: transform;

            &.no-transform {
                transform: scale(1) !important;
            }
        }

        .slide-text {
            @apply tw-absolute tw-mx-8 tw-mb-8;

            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;

            @media screen and (min-width: theme("screens.xl")) {
                margin-left: theme("spacing.15");
                margin-right: theme("spacing.15");
                margin-bottom: theme("spacing.15");
            }
        }

        .slide-description {
            max-height: 0;
            overflow-y: hidden;
            opacity: 0;
            transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
            transform: translateZ(0);
        }
    }
}
