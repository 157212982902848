@import "@../../../../vendor/acc-digital/pim-websitebundle/bundles/AccWebsiteBundle/Resources/views/components/GdprConsent/GdprConsent.scss";

:root {
    --cc-primary-color: theme("colors.brand");
    --cc-secondary-color: theme("colors.gray.100");
    --cc-text-color: theme("colors.gray.850");
    --cc-text-color-headline: theme("colors.gray.850");
    --cc-modal-box-background: theme("colors.gray.100");
    --cc-border-radius: theme("borderRadius.md");
    --cc-border-color: theme("colors.gray.200");
    --cc-switch-bg-inactive: theme("colors.gray.200");
    --cc-background: rgba(0, 0, 0, 0.4);
    --cc-btn-primary-hover-bg: #a50013;
    --cc-btn-secondary-hover-bg: theme("colors.gray.200");
    --cc-btn-default-hover-bg: theme("colors.gray.200");
}

.consent-wrapper {
    @media (max-width: theme("screens.md")) {
        left: 50% !important;
        top: 70px !important;
        transform: translateX(-50%) !important;
        bottom: 70px !important;
    }

    .Headline {
        @apply tw-font-bold tw-uppercase tw-text-black tw-text-3xl tw-mb-5;
    }

    .script-actions-btn.is-secondary {
        @apply tw-text-brand;
    }
}

.GdprConsentBrick > button {
    @apply tw-text-gray-100 tw-origin-center tw-transition-transform tw-duration-300 tw-ease-out hover:tw-scale-105;
}

.gdpr {
    &-element {
        @apply tw-w-full tw-h-full;
    }

    &-wrap {
        @apply tw-w-full tw-h-full;
    }

    &-text {
        @apply tw-w-full tw-h-full ;
    }
}
