.Link {
  @apply tw-select-none;

  &.is-red {
    @apply tw-text-brand tw-duration-300 tw-ease-out tw-cursor-pointer;

    background-image: linear-gradient(transparent, theme("colors.brand") 1px);
    background-size: 0 2px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    &:hover {
      background-size: 100% 2px;
    }
  }

  &.is-black {
    @apply tw-text-gray-800 tw-duration-300 tw-ease-out tw-cursor-pointer;

    background-image: linear-gradient(transparent, theme("colors.gray.800") 1px);
    background-size: 0 2px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    &:hover {
      background-size: 100% 2px;
    }
  }

  &.is-anchor {
    @apply tw-text-brand tw-duration-300 tw-ease-out tw-cursor-pointer;

    background-image: linear-gradient(transparent, theme("colors.brand") 1px);
    background-size: 0 2px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    &:hover {
      @apply tw-text-black;
      background-image: linear-gradient(transparent, theme("colors.black") 1px);
      background-size: 100% 2px;
    }
  }

  .back-arrow {
    top: 50%;
    left: -1rem;
    transform: translate(0, -50%);
  }
}

.has-hover-shadow {
  &:hover {
    &::before {
      @apply tw-opacity-100;
    }
  }

  &::before {
    @apply tw-absolute tw-inset-0 tw-shadow-xl tw-opacity-0 tw-duration-200 tw-transition-opacity tw-ease-in tw-pointer-events-none;

    content: "";
  }
}

.LinkListBig {
  li {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
    color: theme("colors.black");
  }

  &:hover li {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  li + li {
    @apply tw-border-t tw-border-gray-100;
  }

  a, .big-link {
    @apply tw-block tw-relative tw-py-4 tw-pr-6;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%) rotate(45deg);
      height: 6px;
      width: 6px;
      border-top: 2px solid theme("colors.brand");
      border-right: 2px solid theme("colors.brand");
    }
  }
}
