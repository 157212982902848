.Guestbook {
    background-image: url("/bundles/lindner/images/bg-guestbook.png");
    background-size: auto 600px;
    background-position: 50% -100px;
    background-repeat: no-repeat;

    @media screen and (min-width: theme("screens.xxl")) {
        background-size: 88vw;
    }

    .entry {
        &-body {
            @apply tw-relative;
        }

        .has-line {
            @media screen and (min-width: theme("screens.lg")) {
                &::before {
                    @apply tw-absolute tw-bg-gray-300;

                    content: "";
                    top: 0;
                    left: -42px;
                    width: 1px;
                    height: 100%;
                }
            }
        }

        .avatar {
            position: absolute;
            left: -65px;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 1;
        }

        + .entry {
            @apply tw-mt-6;
        }
    }

    .entry-answers {
        .avatar {
            left: -61.5px;
        }
    }

    .attachment {
        position: relative;

        &.has-overlay {
            &:hover {
                &::before {
                    opacity: 0.7;
                }

                &::after {
                    opacity: 1;
                    transition-delay: 0.1s;
                }
            }

            &::before {
                @apply tw-absolute tw-inset-0;

                content: "";
                background-color: theme("colors.brand");
                opacity: 0;
                transition: opacity 0.3s
                    theme("transitionTimingFunction.deceleration");
            }

            &::after {
                @apply tw-absolute tw-inset-0 tw-z-2;

                content: "";
                background-image: url("/bundles/lindner/images/icons/download.svg");
                background-size: 36px 36px;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 0;
                transition: opacity 0.3s
                    theme("transitionTimingFunction.deceleration");
            }
        }
    }

    .GuestBookTeaser {
        .has-inset-bg + .has-inset-bg {
            @media screen and (min-width: theme("screens.md")) {
                margin-top: 0;
            }
        }
    }
}
