.anchor-nav {
    $bar-width: 2px;

    top: 50px;
    bottom: 0;
    z-index: 20;

    @media screen and (min-width: theme("screens.md")) {
        top: 80px;
    }

    &-wrap {
        top: 100%;
        transform: translate(0, -4rem);
        transition: top 1s theme("transitionTimingFunction.in-out"),
            transform 1s theme("transitionTimingFunction.in-out"),
            opacity 0.3s theme("transitionTimingFunction.in-out"),
            visibility 0.3s theme("transitionTimingFunction.in-out");
    }

    .scroll-btn {
        transform: rotate(-90deg);
        transform-origin: center;
        pointer-events: all;
        outline: none;

        &-wrap {
            transform: translate(-50%, -3rem);
            transition: visibility 0.6s theme("transitionTimingFunction.in-out"),
                opacity 0.6s theme("transitionTimingFunction.in-out"),
                transform 1s theme("transitionTimingFunction.in-out");
        }
    }

    nav {
        pointer-events: all;
        width: 10px;

        li {
            @for $i from 1 through 20 {
                &:nth-child(#{$i}) a {
                    transition-delay: #{0 + (0.04 * $i)}s;
                }
            }

            &:before {
                @apply tw-block tw-absolute tw-h-full tw-bg-black tw-left-0;
                content: "";
                top: 50%;
                width: $bar-width;
                pointer-events: all;
                transform: translate(-50%, -50%);
                transition: background 0.3s
                        theme("transitionTimingFunction.in-out"),
                    height 0.5s 0.3s theme("transitionTimingFunction.in-out");
            }
        }

        a {
            transform: translate(-1.25rem, 0);
            transition: all 0.25s cubic-bezier(0.41, 0.26, 0.2, 0.62);
            pointer-events: none;
        }

        &:before {
            @apply tw-absolute tw-block tw-bg-brand;
            content: "";
            width: $bar-width;
            z-index: 3;
            animation: scrollIndicator 4s infinite;
            transition: opacity 0.3s theme("transitionTimingFunction.in-out"),
                visibility 0.3s theme("transitionTimingFunction.in-out");
        }
    }

    &.show-nav {
        .anchor-nav-wrap {
            top: 50%;
            transform: translate(0, -50%);
        }

        .scroll-btn-wrap {
            @apply tw-invisible tw-opacity-0;
            transform: translate(-50%, -6rem);
        }

        nav {
            &:hover {
                a {
                    @apply tw-visible tw-opacity-100;
                    transform: translate(0, 0);
                }
            }

            li {
                a {
                    pointer-events: all;
                }

                &:before {
                    height: calc(100% - 6px);
                }

                &.active {
                    &:before {
                        @apply tw-bg-brand;
                    }
                }
            }

            &:before {
                @apply tw-invisible tw-opacity-0;
                animation: none;
            }
        }
    }

    &.show-scroll-top {
        .scroll-btn {
            @apply tw-hidden;

            &-top {
                @apply tw-block;
            }
        }

        nav {
            &:before {
                animation-direction: reverse;
            }
        }
    }

    @keyframes scrollIndicator {
        0% {
            height: 0;
            top: 0%;
            transform: translate(-50%, -100%);
        }

        50% {
            height: 30px;
        }

        100% {
            height: 0;
            top: 100%;
            transform: translate(-50%, 100%);
        }
    }
}
