.TractorColorPreview {
    .pagination {
        line-height: 0;
    }

    .pagination-bullet {
        @apply tw-relative tw-block tw-w-5 tw-h-5 tw-bg-current tw-opacity-100;

        font-size: 0;
        margin-left: theme("spacing.2") !important;
        margin-right: theme("spacing.2") !important;
        box-shadow: inset 0 0 3px theme("colors.gray.400");
        border-radius: 30px;

        &::before {
            content: "";
            position: absolute;
            top: -5px;
            left: -5px;
            bottom: -5px;
            right: -5px;
            border: 2px solid currentColor;
            border-radius: 30px;
            opacity: 0;
            transform: scale(0.2);
            transition: transform 0.2s ease, opacity 0.3s ease;
        }

        &-active {
            &::before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: theme("colors.brand") !important;
    }

    .swiper-button-prev {
        left: 0.75rem !important;

        @media screen and (min-width: theme("screens.md")) {
            left: 2rem !important;
        }
    }

    .swiper-button-next {
        right: 0.75rem !important;

        @media screen and (min-width: theme("screens.md")) {
            right: 2rem !important;
        }
    }
}
