.AB-Product-Finder {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        pointer-events: none;
        background-color: transparent;
        border-top: theme("spacing.5") solid theme("colors.white");
        border-bottom: theme("spacing.5") solid theme("colors.white");

        @media screen and (min-width: theme("screens.md")) {
            border: theme("spacing.5") solid theme("colors.white");
        }
    }

    .info-headline {
        @media screen and (min-width: theme("screens.md")) {
            margin-right: 25%;
        }
    }

    .answers {
        .answer-item {
            transition-property: all;
            border: 2px solid theme("colors.white");

            &.is-selected {
                border-color: theme("colors.brand");

                button {
                    .number {
                        background: theme("colors.brand");
                        color: theme("colors.white");
                    }
                }
            }

            .number {
                @apply tw-relative tw-block tw-transition-colors tw-duration-300 tw-ease-in-out;
                background: theme("colors.gray.100");
                height: 2em;
                width: 2em;
                border-radius: 99px;

                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                }
            }

            &:hover,
            &:focus {
                border-color: theme("colors.gray.800") !important;
            }
        }
    }

    progress {
        height: 10px;
        border-radius: 8px;
    }

    progress::-webkit-progress-bar,
    progress::-moz-progress-bar {
        height: 10px;
    }

    progress::-webkit-progress-value,
    progress::-moz-progress-bar {
        background-color: theme("colors.brand");
        transition: all 0.3s linear;
        border-radius: 8px;
    }
}
