.AxleLoadCalculator {
    // .vfm__content {
    //     max-height: 95vh;
    //     overflow-y: auto;
    // }

    .detail-link {
        position: relative;
        padding-right: 1.5em;
        color: theme("colors.brand");

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 0.5em;
            height: 0.5em;
            border-left: 1px solid currentColor;
            border-bottom: 1px solid currentColor;
            transform: translate(-50%, -70%) rotate(-45deg);
        }

        &.is-flipped::before {
            transform: translate(-50%, -20%) rotate(135deg);
        }
    }

    .weight-data {
        li {
            @apply tw-relative;

            &::before {
                content: "";
                position: absolute;
                top: 49%;
                left: 10px;
                right: 10px;
                border: 1px dashed theme("colors.gray.300");
            }
        }
    }

    .UsedMachineryHeader {
        .header-image {
            opacity: 0.05;

            @media screen and (min-width: theme("screens.lg")) {
                opacity: 1;
            }
        }
    }

    .AttachmentInfo {
        .mount-weight {
            top: 100%;
            left: 50%;
            transform: translate(-50%, 0);
        }

        .distance-label {
            @apply tw-absolute tw-right-0;

            bottom: 30%;
            width: 40%;

            &.is-left {
                right: unset;
                left: 0;
            }

            img {
                @apply tw-block tw-w-full;
            }

            span {
                @apply tw-absolute tw-text-center tw-bg-gray-100;

                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                min-width: 50%;
                z-index: 1;
            }
        }
    }

    .AxleLoadCalculatorMainInfo {
        .mount-weight {
            @apply tw-absolute tw-text-center;

            &.at-bottom {
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        &.has-tractor {
            .mount-weight {
                &.at-top-left {
                    top: 0;
                    left: 15%;
                    transform: translate(-60%, -50%);

                    @media screen and (min-width: theme("screens.md")) {
                        transform: translate(-50%, -10%);
                    }
                }

                &.at-top-right {
                    top: 0;
                    right: 20%;
                    transform: translate(60%, -50%);

                    @media screen and (min-width: theme("screens.md")) {
                        transform: translate(50%, -10%);
                    }
                }
            }
        }

        &.has-unitrac {
            .mount-weight {
                &.at-top-left {
                    top: 0;
                    left: 29%;
                    transform: translate(-50%, -20%);

                    @media screen and (min-width: theme("screens.md")) {
                        transform: translate(-50%, -10%);
                    }
                }

                &.at-top-right {
                    top: 0;
                    right: 15%;
                    transform: translate(50%, -20%);

                    @media screen and (min-width: theme("screens.md")) {
                        transform: translate(50%, -10%);
                    }
                }
            }
        }
    }

    .unitrac-graphic {
        .cls-1,
        .cls-3,
        .cls-4,
        .cls-6,
        .cls-7 {
            fill: none;
        }

        .cls-1,
        .cls-3,
        .cls-4 {
            stroke: #ccc;
            stroke-width: 12px;
        }

        .cls-1,
        .cls-3 {
            stroke-linecap: round;
        }

        .cls-1 {
            stroke-linejoin: round;
        }

        .cls-2 {
            fill: #ccc;
        }

        .cls-6,
        .cls-7 {
            stroke: #333;
        }

        .cls-6 {
            stroke-dasharray: 5 5;
        }

        .axis-error {
            stroke: #e2001a;
        }
    }

    .tractor-graphic {
        .cls-1,
        .cls-2,
        .cls-3 {
            fill: none;
        }
        .cls-1 {
            stroke: #ccc;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 12px;
        }
        .cls-2,
        .cls-3 {
            stroke: #333;
        }
        .cls-2 {
            stroke-dasharray: 5 5;
        }
        .axis-error {
            stroke: #e2001a;
        }

        .tractor-graphic {
            transform: scale(1.2);
            transform-origin: bottom center;

            @media screen and (min-width: theme("screens.md")) {
                transform: scale(1);
            }
        }
    }
}
