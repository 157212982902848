@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'globals';
@import 'font-faces';

@import 'buttons';
@import 'fonts';
@import 'links';
@import 'tooltip';
@import 'loader';
@import 'litepicker';
@import 'lazy-loading';
@import 'social-media-links';
@import 'quill';
@import 'modal';
@import 'toast';
@import 'swiper';
@import 'gdpr';
@import 'height';
@import 'spotlight';

@import 'pages/index';
@import 'frontend/main';

@import 'components';
@import 'form';

