.News {
    max-width: 40rem;

    .title::after {
        content: "-";
        padding: 0 0.5rem;
    }

    .pimcore_tag_link_text a {
        @extend .Link, .is-red;
    }

    .rotate-270deg {
        transform: translate(-50%, -50%) rotate(270deg);
        white-space: nowrap;
        top: 50%;
        left: 50%;
    }
}