.AB-Horizontal-Teaser-Slider {
    @apply tw-bg-white;

    .active-nav {
        @apply tw-text-black;
    }

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        display: flex;
        height: initial;

        &.placeholder-desktop {
            display: none;

            // needed to get visual active tab-index on desktop if 2 elements due to showing 2.1
            @media screen and (min-width: theme("screens.md")) {
                display: flex;
            }
        }
    }

    .slide-inner {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 25%;
            left: 0;
            right: 0;
            bottom: 0;
            background: theme("colors.gray.100");
            z-index: -1;

            @media screen and (min-width: theme("screens.md")) {
                top: 30%;
            }
        }
    }

    &.is-dark {
        @apply tw-bg-gray-800 tw-text-white;

        .active-nav {
            @apply tw-text-white;
            border-color: theme("colors.white");
        }

        .slide-inner {
            &:before {
                background: theme("colors.gray.850");
                opacity: 0.5;
            }
        }
    }

    .slide-text .text-content{
        a{
            @apply tw-relative tw-select-none tw-text-brand tw-duration-300 tw-ease-out tw-cursor-pointer;

            background-image: linear-gradient(transparent, theme('colors.brand') 1px);
            background-size: 0 2px;
            background-position: 100% 100%;
            background-repeat: no-repeat;

            &:hover {
                background-size: 100% 2px;
            }
        }
    }
}
