.TractorFourWheelSteering {
    .steering-images {
        padding-left: 8%;
    }

    .steering-track {
        opacity: 0.4;
        transform: translate(25vw, 0);
        max-width: 180vw;

        @media screen and (min-width: theme("screens.md")) {
            top: 50%;
            transform: translate(0, -50%);
            max-width: 100vw;
        }
    }

    .steering-tractor {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50vw;
        z-index: 10;

        @media screen and (min-width: theme("screens.md")) {
            left: unset;
            transform: translate(0, -50%);
            width: 25vw;
        }
    }

    .steering-degrees {
        bottom: 20vw;
        right: 50%;
        text-align: center;
        transform: translate(150%,-100%);

        @media screen and (min-width: theme("screens.md")) {
            top: 50%;
            bottom: unset;
            right: 0;
            left: unset;
            transform: translate(15%, -50%);
        }
    }
}
