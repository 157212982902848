.TextInput {
    .icon,
    .vbla {
        @apply tw-absolute tw-h-5 tw-w-5 tw-flex tw-items-center;

        top: 50%;
        transform: translate(0, -50%);

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        &.is-left {
            left: theme("spacing.5");
        }

        &.is-right {
            right: theme("spacing.5");
        }
    }

    .vbla {
        left: theme("spacing.5");
    }
}

// Hide default date picker
.DateInput {
    input::-webkit-calendar-picker-indicator {
        display: none;
    }
    input[type="date"]::-webkit-input-placeholder {
        visibility: hidden !important;
    }
}

.EditorInput {
    .ql-container {
        font: inherit;
    }

    .ql-editor {
        min-height: 5em;
        padding: 0;
        overflow-x: hidden;

        a {
            white-space: break-spaces;
        }

        &::before {
            left: 0;
        }
    }
}

.wysiwyg-label{
    a{
        @apply tw-text-brand hover:tw-underline;
    }
}