.Footer {
    position: relative;

    .main {
        .main-item + .main-item {
            @apply tw-mt-10;

            @media screen and (min-width: theme("screens.lg")) {
                margin-top: 0;
            }
        }
    }
}
