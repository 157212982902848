.BackgroundReplacement {
    background-image: url("/bundles/lindner/images/map.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (min-width: theme("screens.lg")) {
    .Background {
        height: calc(100vh - 80px);
    }
}
