.Button {
  @apply tw-py-4 tw-px-8 tw-font-bold tw-rounded-2 tw-border tw-border-brand tw-text-center tw-select-none;

  &.is-primary {
    @apply tw-bg-brand tw-text-white;

    &:hover,
    &:focus {
      @apply tw-bg-red-400 tw-border-red-400;
    }

    &:active {
      @apply tw-bg-red-600 tw-border-red-600;
    }
  }

  &.is-secondary {
    @apply tw-bg-gray-800 tw-border-gray-800 tw-text-white;

    &:hover,
    &:focus {
      @apply tw-bg-gray-500 tw-border-gray-500;
    }

    &:active {
      @apply tw-bg-black tw-border-black;
    }
  }

  &.is-outlined {
    @apply tw-bg-transparent tw-text-brand;

    &:active,
    &:hover {
      @apply tw-bg-brand tw-text-white;
    }
  }

  &.is-outlined-secondary {
    @apply tw-bg-transparent tw-text-brand tw-border-gray-300 tw-bg-white;
    @apply tw-font-normal tw-rounded #{!important};

    &:active,
    &:hover {
      @apply tw-bg-brand tw-text-white tw-border-brand;
    }
  }

  &[disabled],
  &.is-disabled {
    @apply tw-bg-gray-300 tw-border-gray-300 tw-cursor-not-allowed;

    &:hover,
    &:active,
    &:focus {
      @apply tw-bg-gray-400 tw-border-gray-400;
    }
  }

  &.is-link {
    @apply tw-border-transparent;

    &:hover,
    &:active,
    &:focus {
      @apply tw-bg-gray-200 tw-border-gray-200;
    }
  }

  &.is-size-sm {
    @apply tw-py-2/5 tw-px-5 tw-font-bold tw-rounded-2;
  }
}
