.HeaderAttachments {
  .AttachmentLinkListBig {     
    .pimcore_editable_block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .pimcore_block_entry {
      width: 48%;
    }

    .pimcore_tag_link_text {
      @apply tw-block tw-relative tw-py-4 tw-pr-6 tw-border-b tw-border-black;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%) rotate(45deg);
        height: 6px;
        width: 6px;
        border-top: 2px solid theme("colors.brand");
        border-right: 2px solid theme("colors.brand");
      }
    }
  }
}