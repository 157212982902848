.TractorTraclink {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(229, 229, 229, 1) 100%
    );

    .has-inset-bg + .has-inset-bg {
        @media screen and (min-width: theme("screens.md")) {
            margin-top: 0;
        }
    }

    .info-headline {
        @media screen and (min-width: theme("screens.md")) {
            margin-right: 25%;
        }
    }
}
