.js-toast {
    @apply tw-fixed tw-right-20 tw-bottom-20 tw-left-20 tw-w-fit tw-p-10 tw-bg-white tw-border-t tw-shadow-xl;

    animation: fade-in both 1s ease-out;

    @keyframes fade-in {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    strong {
        @apply tw-font-normal;
    }

    &.success {
        @apply tw-border-green-500;

        strong {
            @apply tw-text-green-500;
        }
    }

    &.error {
        @apply tw-border-t-brand;

        strong {
            @apply tw-text-brand;
        }
    }
}
