.BaseLoader {
    .circle {
        width: 3rem;
        height: 3rem;
        border: 5px solid;
        border-color: theme("colors.brand") transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
