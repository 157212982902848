:root{
    --screen-height-minus-header: calc(100vh - 48px);

    @media (min-width: theme("screens.lg")) {
        --screen-height-minus-header: calc(100vh - 78px);
    }
}

.height-screen{
    height: var(--screen-height-minus-header);
}

.max-height-screen{
    max-height: var(--screen-height-minus-header);
}

.min-height-screen{
    min-height: var(--screen-height-minus-header);
}