@import "@templates/tractors-transporters/series/detail/hero";
@import "@templates/tractors-transporters/series/detail/cabin";
@import "@templates/tractors-transporters/series/detail/anchor-nav";
@import "@templates/tractors-transporters/series/detail/l-drive";
@import "@templates/tractors-transporters/series/detail/engine";
@import "@templates/tractors-transporters/series/detail/four-wheel-steering";
@import "@templates/tractors-transporters/series/detail/trac-link";
@import "@templates/tractors-transporters/series/detail/light";
@import "@templates/tractors-transporters/series/detail/dimensions";
@import "@templates/tractors-transporters/series/detail/tyre-sets";
@import "@templates/tractors-transporters/series/detail/color-preview";
@import "@templates/tractors-transporters/series/detail/downloads";
@import "@templates/tractors-transporters/series/detail/offer";

.Tractor {
    .Dimensions + section {
        @apply tw-pt-20 md:tw-pt-40 lg:tw-pt-64;
    }
}
