.FinanceCalculator {
    .list-dashed {
        li {
            @apply tw-relative tw-flex tw-justify-between tw-my-2;

            &::before {
                content: "";
                position: absolute;
                top: 1em;
                height: 1px;
                left: 0;
                right: 0;
                border-bottom: 1px dashed theme("colors.gray.300");
            }

            span {
                &:first-child {
                    @apply tw-pr-2;
                }

                &:last-child {
                    @apply tw-pl-2;
                }

                @apply tw-inline-block tw-relative tw-bg-gray-100;
            }
        }
    }

    .circle {
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        clip-path: circle(50% at 50% 50%);
    }

    .segment {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &.rates-third{
        .segment{
            clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 0 100%);
        }

        .segment:nth-child(1) {
            background-color: theme("colors.brand");
            transform: rotate(-220deg);
        }
    
        .segment:nth-child(2) {
            background-color: theme("colors.gray.500");
            transform: rotate(-90deg);
        }
    
        .segment:nth-child(3) {
            background-color: theme("colors.gray.300");
            transform: rotate(22deg);
        }
    }

    &.rates-fifth{
        .segment{
            clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 34%);
        }

        .segment:nth-child(1) {
            background-color: theme("colors.brand");
        }
    
        .segment:nth-child(2) {
            background-color: theme("colors.gray.500");
            transform: rotate(72deg);
        }
    
        .segment:nth-child(3) {
            background-color: theme("colors.gray.400");
            transform: rotate(144deg);
        }

        .segment:nth-child(4) {
            background-color: theme("colors.gray.300");
            transform: rotate(216deg);
        }
    
        .segment:nth-child(5) {
            background-color: theme("colors.gray.200");
            transform: rotate(288deg);
        }
    }

    .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: theme("colors.gray.100");
        width: calc(100% - 60px);
        aspect-ratio: 1 / 1;
        z-index: 2;

        @media screen and (min-width: theme("screens.md")) {
            width: calc(100% - 160px);
        }

        @media screen and (min-width: theme("screens.xl")) {
            width: calc(100% - 110px);
        }

        @media screen and (min-width: theme("screens.xxl")) {
            width: calc(100% - 160px);
        }

        .wrap {
            @apply tw-absolute tw-left-1/2 tw-top-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2;
            width: 90%;
            height: 75%;

            @media screen and (min-width: theme("screens.md")) {
                width: 75%;
            }

            .finance-image {
                @apply tw-relative tw-block tw-h-auto tw-overflow-hidden;
                aspect-ratio: 4 / 3;
                width: 75%;
                margin: 0 auto;
                background: none;

                @media screen and (min-width: theme("screens.xxl")) {
                    width: 90%;
                }

                @media screen and (min-width: theme("screens.xxxl")) {
                    width: 100%;
                }

                img {
                    object-fit: contain;
                }
            }
        }
    }
}
