.AB-FormContact {
    .location-item {
        @apply tw-pl-10;

        counter-increment: location;

        &::before {
            @apply tw-top-2 tw-pt-1 tw-text-white tw-text-center tw-text-sm tw-font-bold lg:tw-top-6;

            content: counter(location);
            position: absolute;
            display: block;
            left: 0;
            background-image: url("/bundles/lindner/images/icons/pin-filled.svg");
            background-repeat: no-repeat;
            background-size: 100% auto;
            width: 1.5rem;
            height: 2.5rem;
        }
    }
}
