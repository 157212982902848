.Header {
    z-index: 11000;

    .news-category {
        span {
            transform: translate(-50%, -50%) rotate(270deg);
            white-space: nowrap;
            top: 50%;
            left: 50%;
        }
    }

    .menu-nav {
        .menu-item {
            transition: opacity 0.3s theme("transitionTimingFunction.in-out");
        }

        &.is-open {
            .menu-item:not(.is-active) {
                @apply tw-opacity-25;
            }
        }
    }
}

.MenuBurger {
    &.is-active {
        .menu-bar:first-child {
            animation: menubartop 1s normal
                theme("transitionTimingFunction.deceleration");
            animation-fill-mode: both;
        }

        .menu-bar:nth-child(2) {
            opacity: 0;
            transition-delay: 0.5s;
        }

        .menu-bar:last-child {
            animation: menubarbottom 1s normal
                theme("transitionTimingFunction.deceleration");
            animation-fill-mode: both;
        }
    }

    .menu-bar {
        left: 0;
        display: block;
        height: 2px;
        background: theme("colors.gray.800");
    }

    .menu-bar:first-child {
        top: 1px;
    }

    .menu-bar:nth-child(2) {
        top: 50%;
        transform: translate(0, -50%);
        transition: opacity 0.2s theme("transitionTimingFunction.deceleration");
    }

    .menu-bar:last-child {
        bottom: 1px;
    }
}

@keyframes menubartop {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    50% {
        transform: translate(0, 10px) rotate(0);
    }
    100% {
        transform: translate(0, 10px) rotate(-45deg);
    }
}

@keyframes menubarbottom {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    50% {
        transform: translate(0, -10px) rotate(0);
    }
    100% {
        transform: translate(0, -10px) rotate(45deg);
    }
}

.TheMenu {
    backface-visibility: hidden;

    .has-submenu {
        svg {
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .meta-side {
        backface-visibility: hidden;
        will-change: transform;
        transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
        // transition-delay: 0.3s;

        &-dynamic {
            &.has-text {
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 60%;
                    opacity: 0.85;
                    pointer-events: none;
                    background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 1) 0%,
                        rgba(0, 0, 0, 0) 100%
                    );
                }
            }
        }
    }

    .content-side {
        backface-visibility: hidden;
        will-change: transform;
        transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
        transition-delay: 0.3s;
    }

    .has-submenu {
        svg {
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .menu-with-border {
        @apply tw-relative tw-pt-6;

        &::before {
            @apply tw-block tw-absolute tw-top-0 tw-left-8 tw-right-8 tw-bg-black;
            content: "";
            height: 1px;
        }
    }

    .menu-icon {
        top: 50%;
        transform: translate(0, -50%);
    }

    @apply tw-transition-transform tw-duration-1000 tw-ease-in-out;
    
    .meta-side{
        @apply tw-transition-transform tw-duration-1000 tw-ease-in-out;
    }

    &.is-closed{
        @apply -tw-translate-y-full tw-delay-150;

        .meta-side{
            @apply -tw-translate-y-full;
        }
    }

    &.is-opened{
        @apply tw-translate-y-0;

        .meta-side{
            @apply tw-translate-y-0;
        }
    }
}

@keyframes menuFade {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}