@mixin SliderTransform($grid) {
    transform: translateX(calc((((100vw - ($grid)) / 2) + 2rem) * -1));
}

.CommonImageSliderThumbs {
    &.do-contain-images {
        .swiper-wrapper-main {
            height: 256px;

            @media screen and (min-width: theme("screens.md")) {
                height: 384px;
            }
            @media screen and (min-width: theme("screens.lg")) {
                height: 512px;
            }

            .slide-inner {
                height: 100%;
            }
        }

        div[class*="tw-aspect-4/3"] {
            .swiper-wrapper-main {
                height: 100% !important;
            }
        }

        .BaseImage {
            height: 100%;
        }
    }

    .slide-inner {
        position: relative;
    }

    .swiper-slide-thumb {
        .slide-inner {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0.5;
                background: theme("colors.gray.800");
                transition: opacity theme("transitionTimingFunction.out") 0.3s;
            }
        }

        &.swiper-slide-active {
            .slide-inner::before {
                opacity: 0;
            }
        }
    }

    &.is-sticky {
        @apply md:tw-sticky md:tw-left-0 md:tw-right-0;

        @media screen and (min-width: theme("screens.md")) {
            top: theme("header.height.sm");
            transform: translateX(-2rem);
        }

        @media screen and (min-width: theme("screens.lg")) {
            top: theme("header.height.lg");
            @include SliderTransform(theme("screens.lg"));
        }

        @media screen and (min-width: theme("screens.xl")) {
            @include SliderTransform(theme("screens.xl"));
        }

        @media screen and (min-width: theme("screens.xxl")) {
            @include SliderTransform(theme("screens.xxl"));
        }

        @media screen and (min-width: theme("screens.xxxl")) {
            @include SliderTransform(theme("screens.xxxl"));
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    color: theme("colors.brand") !important;
    --swiper-navigation-size: theme("spacing.5");
}

.swiper-button-prev {
    left: 0.75rem !important;

    @media screen and (min-width: theme("screens.md")) {
        left: 2rem !important;
    }
}

.swiper-button-next {
    right: 0.75rem !important;

    @media screen and (min-width: theme("screens.md")) {
        right: 2rem !important;
    }
}
