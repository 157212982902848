.Link {
  @apply tw-select-none;

  &.is-red {
    @apply tw-text-brand tw-duration-300 tw-ease-out tw-cursor-pointer;

    background-image: linear-gradient(transparent, theme('colors.brand') 1px);
    background-size: 0 2px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    &:hover {
      background-size: 100% 2px;
    }
  }

  &.is-black {
    @apply tw-text-gray-800 tw-duration-300 tw-ease-out tw-cursor-pointer;

    background-image: linear-gradient(transparent, theme('colors.gray.800') 1px);
    background-size: 0 2px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    &:hover {
      background-size: 100% 2px;
    }
  }

  &:disabled,
  &.is-disabled {
    @apply tw-text-gray-400 tw-pointer-events-none;
  }

  .back-arrow {
    top: 50%;
    left: -1rem;
    transform: translate(0, -50%);
  }
}

.has-hover-shadow {
  &:hover {
    &::before {
     @apply tw-opacity-100;
    }
  }

  &::before {
    @apply tw-absolute tw-inset-0 tw-shadow-xl tw-opacity-0 tw-duration-200 tw-transition-opacity tw-ease-in tw-pointer-events-none;

    content: '';
  }
}

.is-wysiwyg {
  h2 {
    @apply tw-font-black tw-leading-h2;

    font-size: 1.75rem;
    margin-top: 2em;
    margin-bottom: 1em;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 2rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 2.25rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    @apply tw-font-light tw-leading-h3 tw-text-2xl;

    margin-top: 2em;
    margin-bottom: 1em;

    @media screen and (min-width: theme('screens.md')) {
      font-size: 2rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 2.25rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    @apply tw-text-xl tw-leading-h4;

    margin-top: 2em;
    margin-bottom: 1em;

    &:not(.is-light) {
      @apply tw-font-bold;
    }

    @media screen and (min-width: theme('screens.md')) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: theme('screens.xl')) {
      font-size: 1.75rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  p + p {
    margin-top: 1em;
  }

  a {
    @apply tw-relative tw-select-none tw-text-brand tw-duration-300 tw-ease-out tw-cursor-pointer;

    background-image: linear-gradient(transparent, theme('colors.brand') 1px);
    background-size: 0 2px;
    background-position: 100% 100%;
    background-repeat: no-repeat;

    &:hover {
      background-size: 100% 2px;
    }
  }

  ol,
  ul {
    @apply tw-flex tw-flex-col tw-list-outside;
    padding-left: 1em;
  }

  ol {
    @apply tw-list-decimal;
  }

  ul {
    @apply tw-list-disc;
  }
}

a.expand-click-area {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// in case element is linked via #id
// add offset due to headerbar
.AnchorTarget {
  &.is-permanent::before,
  &:target::before {
    content: '';
    display: block;
    height: 100px;
    margin-top: -100px;

    @media screen and (min-width: theme('screens.lg')) {
      height: 140px;
      margin-top: -140px;
    }
  }
}
