.LindnerMoments {
    .list {
        column-count: 1;

        @media screen and (min-width: theme("screens.md")) {
            column-count: 2;
            column-gap: theme("spacing.5");
        }
    }

    .LindnerMomentsItem {
        break-inside: avoid;

        .plyr__video-embed {
            @apply tw-relative;

            > iframe {
                @apply tw-w-full tw-h-full;
            }
        }
    }

    .LindnerMomentsItemVoting {
        .spinner {
            &::after {
                @apply tw-block tw-w-10 tw-h-10 tw-rounded-full tw-relative;
                content: " ";
                display: block;
                border-radius: 50%;
                border: 4px solid theme("colors.brand");
                border-color: theme("colors.brand") transparent
                    theme("colors.brand") transparent;
                animation: spinner 1.2s linear infinite;
            }
        }

        @keyframes spinner {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
