@import '@modules/tippy.js/dist/tippy.css';


.custom-tooltip {
    @apply tw-bg-white -tw-translate-x-1/2 tw-absolute tw-w-76 tw-left-1/2 tw-shadow-lg tw-bottom-full;
    z-index: 9999999;

    &::after {
        content: "";

        @apply tw-absolute tw-left-1/2 tw-bottom-0 tw-translate-y-1/2 -tw-translate-x-1/2 tw-w-5 tw-h-5 tw-rotate-45 tw-bg-white;
    }
}

.custom-tooltip-arrow::after{
    content: "";

    @apply tw-absolute -tw-z-10 tw-left-1/2 tw-bottom-0 tw-translate-y-1/2 -tw-translate-x-1/2 tw-w-5 tw-h-5 tw-rotate-45 tw-bg-white;
}

.tippy-box[data-theme~="lindner"] {
    @apply tw-bg-brand tw-text-white;
}

.tippy-box[data-theme~="lindner"][data-placement^="top"]
    > .tippy-arrow::before {
    border-top-color: theme("colors.brand");
}

.tippy-box[data-theme~="lindner-light"] {
    @apply tw-bg-white tw-text-gray-850;
}

.tippy-box[data-theme~="lindner-light"][data-placement^="top"]
    > .tippy-arrow::before {
    border-top-color: theme("colors.white");
}
