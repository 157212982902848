.AB-Text-Module {
    .info-headline {
        @media screen and (min-width: theme("screens.md")) {
            margin-right: 25%;
        }
    }

    .description.is-dark-mode {
        color: theme("colors.gray.400");

        strong {
            color: theme("colors.white");
        }
    }
}
