.BaseVideo {
    .plyr {
        @apply tw-h-full;

        .plyr__poster {
            background-size: cover !important;
        }
    }

    .VideoYoutube:not(.loaded) iframe {
        @apply tw-hidden;
    }
}
