html {
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;

  --plyr-color-main: theme('colors.brand');

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: initial;
  }

  #app {
      background: theme('colors.white');
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.has-inset-bg {
  @apply tw-relative tw-bg-white;

  &::before {
    @apply tw-bg-gray-100;
    z-index: 0;

    content: '';
    position: absolute;
    top: theme('spacing.5');
    bottom: theme('spacing.5');
    right: 0;
    left: 0;

    @media screen and (min-width: theme('screens.md')) {
      right: theme('spacing.5');
      left: theme('spacing.5');
    }
  }

  &-dark::before {
    @apply tw-bg-gray-800;
  }

  & + & {
    margin-top: -1.25rem;

    @media screen and (min-width: theme('screens.md')) {
      margin-top: 0;
    }
  }

  /*
  @media screen and (min-width: theme('screens.md')) and (max-width: 1040px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  */
}

.has-inset-b-t {
  @apply tw-relative;

  &::before {
    @apply tw-bg-gray-300;

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;

    @media screen and (min-width: theme('screens.md')) {
      left: theme('spacing.5');
      right: theme('spacing.5');
    }
  }
}

.list-hover-highlight {
  li {
    transition: opacity 0.3s theme('transitionTimingFunction.in-out');
  }

  &:hover {
    li {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.break-words {
  word-break: break-word;
}