@font-face {
    font-family: 'Roboto';
    src: url('/bundles/lindner/fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('/bundles/lindner/fonts/roboto/Roboto-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('/bundles/lindner/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('/bundles/lindner/fonts/roboto/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('/bundles/lindner/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('/bundles/lindner/fonts/roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('/bundles/lindner/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('/bundles/lindner/fonts/roboto/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('/bundles/lindner/fonts/roboto/Roboto-Black.woff2') format('woff2'),
    url('/bundles/lindner/fonts/roboto/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('/bundles/lindner/fonts/poppins/Poppins-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
  }
  