.EventDetail {
    .emphasized-text {
        strong {
            @apply tw-text-gray-800;
        }
    }

    .accountInfo {
        a {
            @apply tw-text-brand;
        }
    }
}
