.ScrollBar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #c4c4c4;
  }

  &::-webkit-scrollbar-thumb {
    background: theme('colors.brand');
  }
}
